import anime from "animejs/lib/anime.es.js";

document.addEventListener("DOMContentLoaded", function () {
  const Splide = window.Splide;
  const mql = window.matchMedia("(min-width: 800px)");

  var elms = document.querySelectorAll(".splide-slider.fullSlider");
  elms.forEach((elem) => {
    const options = JSON.parse(elem.getAttribute("data-splide"));
    const splideSlide = new Splide(elem, options);

    splideSlide.on("active", (slide) => {
      const slider = document.getElementById(slide.slide.id);

      var imageURL = getComputedStyle(slider).getPropertyValue(
        "--slider-bg-image-lazy"
      );
      imageURL = imageURL.replace(/\\/g, "");
      var matches = imageURL.match(/\bhttps?:\/\/\S+(?=\))/i);
      if (matches && matches.length > 0) {
        var loadImg = matches[0];
        loadBGImage(loadImg).then((image) => {
          slider.style.setProperty("--slider-bg-image", imageURL);
          slider
            .querySelector(".image-zoom")
            .style.setProperty("--slider-bg-image", imageURL);
        });
      }

      slider.querySelector(".image-zoom").style.animation = null;

      if (mql.matches) {
        var letters = "#" + slide.slide.id + " .letter";
        animateText(slider, {
          targets: letters,
          translateX: [30, 0],
          translateZ: 0,
          opacity: [0, 1],
          easing: "easeOutExpo",
          delay: anime.stagger(30, { start: 1000 }),
          begin: function (anim) {
            slider.querySelector(".image-zoom").style.animation =
              "zoomzoom 15s ease-in-out forwards";
          },
        });
      }
    });

    splideSlide.mount();
    const size = splideSlide.length;
    const currentSlideNum = elem.querySelector(
      ".splide-numbered-pagination .current-slide"
    );
    const totalSlideNum = elem.querySelector(
      ".splide-numbered-pagination .total-slides"
    );
    totalSlideNum.textContent = size;
    splideSlide.on("moved", () => {
      currentSlideNum.textContent = splideSlide.index + 1;
    });

    splideSlide.on("visible", (slide) => {
      const slider = document.getElementById(slide.slide.id);

      var imageURL = getComputedStyle(slider).getPropertyValue(
        "--slider-bg-image-lazy"
      );
      imageURL = imageURL.replace(/\\/g, "");
      var matches = imageURL.match(/\bhttps?:\/\/\S+(?=\))/i);
      if (matches && matches.length > 0) {
        var loadImg = matches[0];
        loadBGImage(loadImg).then((image) => {
          slider.style.setProperty("--slider-bg-image", imageURL);
          slider
            .querySelector(".image-zoom")
            .style.setProperty("--slider-bg-image", imageURL);
        });
      }

      slider.querySelector(".image-zoom").style.animation = null;
      if (mql.matches) {
        var letters = "#" + slide.slide.id + " .letter";
        animateText(slider, {
          targets: letters,
          translateX: [30, 0],
          translateZ: 0,
          opacity: [0, 1],
          easing: "easeOutExpo",
          delay: anime.stagger(30, { start: 1000 }),
          begin: function (anim) {
            slider.querySelector(".image-zoom").style.animation =
              "zoomzoom 15s ease-in-out forwards";
          },
        });
      }
    });
    splideSlide.on("hidden", (slide) => {
      const slider = document.getElementById(slide.slide.id);
      if (mql.matches) {
        var letters = "#" + slide.slide.id + " .letter";
        animateText(slider, {
          targets: letters,
          translateX: [0, -30],
          opacity: [1, 0],
          easing: "easeOutExpo",
          delay: anime.stagger(30, { start: 0 }),
        });
      }
    });
  });
});

function animateText(slider, options = {}) {
  const mql = window.matchMedia("(min-width: 800px)");
  if (mql.matches) {
    [".text-big", ".text-small"].forEach((text, i) => {
      var wrap = slider.querySelector(text);
      wrap.innerHTML = wrap.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );
    });
    anime.timeline().add(options);
  }
}

const loadBGImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
